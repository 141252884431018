<template>
    <div>
        <a-button @click="$router.back()" class="mb-4">Regresar</a-button>
        <h2 class="text-2xl mb-4">Asignar materia {{ course.title || $route.params.id }}</h2>
        <div class="flex space-x-2">
            <a-select
                class="flex-grow"
                :loading="loadings.periods"
                label="Período"
                v-model="selection.period"
                :source="periods.map($0 => ({ label: `${$0.year}-${$0.number}`, value: $0.id }))" />

            <a-select
                class="flex-grow"
                :loading="loadings.periods"
                label="Pensum"
                v-model="selection.pensum"
                :source="pensums.map($0 => ({ label: $0.specialization.title, value: $0.id }))" />

            <a-select
                class="flex-grow"
                :loading="loadings.periods"
                label="Semestre"
                v-model="selection.semester"
                :source="semesters.map($0 => ({ label: $0.number, value: $0.id }))" />
        </div>

        <template v-if="selection.semester">
            <div class="mt-4">
                <h2 class="text-2xl mb-2">¿Desea anexar {{ course.title }} junto a?:</h2>

                <div class="grid grid-cols-8 gap-4">
                    <span v-for="_course in semester.courses" class="bg-white p-2 text-center rounded-2xl border border-gray-100 shadow-md flex items-center justify-center" :key="_course.id">
                        {{ _course.title }}
                    </span>
                </div>
            </div>

            <a-button class="mt-4" @click="confirm">Confirmar</a-button>
        </template>
    </div>
</template>

<script>
export default {
    data: () => ({
        periods: [],
        selection: {
            period: null,
            pensum: null,
            semester: null
        },
        loadings: {
            periods: false,
            course: false
        },
        course: {}
    }),
    computed: {
        period() {
            return this.periods.find($0 => $0.id === this.selection.period)
        },
        pensum() {
            return this.pensums.find($0 => $0.id === this.selection.pensum)
        },
        semester() {
            return this.semesters.find($0 => $0.id === this.selection.semester) ?? { courses: [] }
        },
        pensums() {
            return this.period?.pensums ?? []
        },
        semesters() {
            return (this.pensum?.semesters?.filter($0 => !$0.sections.some($1 => !$1.period_id === this.selection.period))) ?? []
        },
    },
    methods: {
        confirm () {
            this.$repository.semesters
                .updateCourses(this.semester?.id, [
                    ...(this.semester?.courses?.map($0 => $0.id) ?? []),
                    this.course?.id
                ])
                .then(() => {
                    this.$message.success({
                        message: `La materia ha sido asignada con éxito`,

                    })

                    this.$router.push({ name: 'courses.all' })
                })
        }
    },
    mounted() {
        this.$repository.periods
            .index({
                limit: 0,
                with: `pensums.semesters.courses,pensums.semesters.sections,pensums.specialization`
                })
            .then(({ data }) => this.periods = data)
            ._loading(() => this.loadings.periods = !this.loadings.periods)

        this.$repository.courses
            .show(this.$route.params.id)
            .then(({ data }) => this.course = data)
            ._loading(() => this.loadings.course = !this.loadings.course)
    }
}
</script>